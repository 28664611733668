import { userHasTouch } from "Everlaw/Core/Sniff";
import * as d3 from "d3";
import dojo_on = require("dojo/on");
import dojo_touch = require("dojo/touch");
import dojox_gesture_Base = require("dojox/gesture/Base");
import dojox_gesture_tap = require("dojox/gesture/tap");

/**
 * We have to monkey-patch the dojo touch virtual events. They suppress the underlying native event,
 * e.g. 'click', so that two events don't get fired. However, suppressing the underlying event also
 * means that focus does not change. Some of our code, such as the notes interface, relies on
 * blurring. Thus, we modify the dojo code to explicitly focus on the target.
 *
 * Also, as of 12/26/2014 the dojox/gesture/Base code itself is out of date - in particular,
 * the condition it quotes below as coming from dojo/touch has been updated at its source
 * to correctly handle audio and video elements, but the dojox gesture copy hasn't been.
 * So, we can't remove this monkey patch just yet...
 */
dojox_gesture_Base.prototype._process = function (element: any, phase: string, e: any) {
    if (e instanceof MouseEvent && e.button !== 0) {
        return; // Ignore right-clicks as they will cause unwanted tap events in Firefox
    }

    e._locking = e._locking || {};
    if (e._locking[this.defaultEvent] || this.isLocked(e.currentTarget)) {
        return;
    }
    // Invoking gesture.press()|move()|release()|cancel()
    // #16900: same condition as in dojo/touch, to avoid breaking the editing of input fields.
    if (
        e.target
        && (e.target.tagName !== "INPUT"
            || e.target.type === "radio"
            || e.target.type === "checkbox")
        && e.target.tagName !== "TEXTAREA"
        && e.target.tagName !== "AUDIO"
        && e.target.tagName !== "VIDEO"
        && e.target.contentEditable !== "true"
    ) {
        //        e.preventDefault();
    }
    e._locking[this.defaultEvent] = true;
    this[phase](element.data, e);
};
/**
 * Patched to fix a bug when differentiating between mouse events being either Input.tap or
 * Input.tap.hold.
 */
dojox_gesture_tap.Tap.prototype.press = function (/*Object*/ data: any, /*Event*/ e: any) {
    // summary:
    //		Overwritten, record initial tap info and register a timeout checker for 'tap.hold'
    if (e.touches && e.touches.length >= 2) {
        //tap gesture is only for single touch
        clearTimeout(data.tapTimeOut);
        delete data.context;
        return;
    }
    const target = e.target;
    this._initTap(data, e);
    const started = Date.now();
    const tryFireTapHold = () => {
        if (this._isTap(data, e)) {
            this.fire(target, { type: "tap.hold" });
        }
        delete data.context;
    };
    data.tapTimeOut = setTimeout(() => {
        if (Date.now() - started - this.holdThreshold < 5) {
            tryFireTapHold();
        } else {
            // Something happened in the browser that significantly delayed this timeout from firing
            // (such as expensive dojo Dnd computations or slow browser (IE11) style calculations and
            // hit detection). In this case, defer this timeout handler and give any pending release
            // events a chance to fire first (and subsequently fire the appropriate tap event which
            // will prevent this potential hold event).
            data.tapTimeOut = setTimeout(() => tryFireTapHold(), 1);
        }
    }, this.holdThreshold);
};

interface Tap extends dojo_on.ExtensionEvent {
    hold: dojo_on.ExtensionEvent;
    doubletap: dojo_on.ExtensionEvent;
}
export const tap: Tap = dojox_gesture_tap;
// dojo_touch.press avoids the focus/blur issues mentioned above.
export const press: dojo_on.ExtensionEvent = dojo_touch.press;
export const release: dojo_on.ExtensionEvent = dojo_touch.release;
export const leave: dojo_on.ExtensionEvent = dojo_touch.leave;
export const enter: dojo_on.ExtensionEvent = dojo_touch.enter;
export const move: dojo_on.ExtensionEvent = dojo_touch.move;

// for use with event#key in a dojo_on
export const ALT = "Alt";
export const BACKSPACE = "Backspace";
export const DELETE = "Delete";
export const SHIFT = "Shift";
export const ARROW_UP = "ArrowUp";
export const ARROW_DOWN = "ArrowDown";
export const ARROW_LEFT = "ArrowLeft";
export const ARROW_RIGHT = "ArrowRight";
export const CONTROL = "Control";
export const ENTER = "Enter";
export const OPTION = "Option";
export const SPACE = " ";
export const TAB = "Tab";
export const ESCAPE = "Escape";

/**
 * Checks to see if the device can trigger touch events.
 * <p>
 * Keep in mind that touch support is independent of mouse support--the presence or absence of one
 * says nothing about the other.
 */
export function canTouch() {
    return userHasTouch();
}

/**
 * Given a paste event and a data type, returns the current clipboard data.
 *
 * We currently only support the `text` type. We should add more types as we need them.
 *
 * TODO: When we add a new type, use an Object mapping type to MIME type instead of hard-coding the
 * different types.
 */
export function getClipboard(pasteEvent: ClipboardEvent, type: string): string | null {
    if (type !== "text") {
        throw 'clipboard data type "' + type + '" is not currently supported.';
    }
    if (pasteEvent.clipboardData) {
        return pasteEvent.clipboardData.getData("text/plain");
    }
    return null;
}

/**
 * Attach a {@code dojo_on} KeyboardEvent handler to {@code node} that will only execute
 * {@code callback} if the key pressed is in {@code keys}. The default event type is "keydown", but
 * {@code handlerType} can be supplied to override this. The possible values for {@code
 * handlerType} are in the dojo_on documentation. Using this method with a non-keyboard event type
 * will result in a listener that's never fired.
 */
export function fireCallbackOnKey(
    node: EventTarget,
    keys: string[],
    callback: (e: KeyboardEvent) => void,
    handlerType = "keydown",
): dojo_on.Handle {
    return dojo_on(node, handlerType, (e: Event) => {
        if (e instanceof KeyboardEvent && keys.indexOf(e.key) >= 0) {
            callback(e);
        }
    });
}

/**
 * Attaches a keyboard event handler to SVG elements. Attaching a handler of the same
 * event type more than once to the same SVG element will overwrite previous handlers.
 *
 * @param nodes the group of SVG elements that the listener should be attached to
 * @param keys a list of keys used to determine if the handler should fire
 * @param callback a function taking in a datum and an index, the arguments are auto-populated when
 * the handler fires
 * @param handlerType a string representing a KeyboardEvent type, should usually be left as the
 * default "keydown"
 */
export function svgFireCallbackOnKey<D>(
    nodes: d3.Selection<D>,
    keys: string[],
    callback: (datum: D, index: number) => void,
    handlerType = "keydown",
): void {
    nodes.on(handlerType, (d: D, i: number) => {
        if (keys.indexOf((<KeyboardEvent>d3.event).key) >= 0) {
            callback(d, i);
        }
    });
}

/**
 * Attach keyboard handlers to a given element which allow it to be scrolled left or right with the
 * arrow keys.
 */
export function makeHorizontallyScrollableWithArrows(elem: HTMLElement): dojo_on.Handle {
    return fireCallbackOnKey(elem, [ARROW_LEFT, ARROW_RIGHT], (evt) => {
        const direction = evt.key === ARROW_RIGHT ? 1 : -1;
        elem.scrollLeft += direction * 200;
    });
}
