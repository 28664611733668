/**
 * Entities - Various HTML entities used throughout our codebase. Since &-specified entites don't
 *  work with Dom's content feature, you can use the variables here instead. Either copy-pasting the
 *  character you want or importing this module under the binding 'E' are acceptable ways to use the
 *  values here. The main exception is E.NBSP, which should always be imported for readability. Feel
 *  free to add more entities as they become useful.
 */
import Dom = require("Everlaw/Dom");

// Non-breaking space character as hex, since it's otherwise indistinguishable from ' ' (space).
export var NBSP = "\xA0";
export var MDASH = "—";
export var NDASH = "–";
export var MIDDOT = "·";
export var BULL = "•";
export var LARR = "←";
export var RARR = "→";
export var ELIP = "…";

/**
 * Accepts an entity name (e.g., 'rarr') and returns the character corresponding to that entity
 * (e.g., '→'). If there is no such entity (e.g., 'bogus'), the return value is undefined (but it
 * will probably be, e.g., '&bogus;').
 */
export function toText(name: string) {
    return Dom.div({ innerHTML: "&" + name + ";" }).textContent;
}
