/**
 * Defines implicit interfaces used throughout our system. By collecting and defining them in one
 * place, we can target these interfaces in functions and class definitions.
 *
 * Be careful about adding more dependencies here, as this module is used by Core.js and other Core
 * libraries.
 */
import Is = require("Everlaw/Core/Is");

/**
 * An Equality object is one that defines an `equals` function that accepts a value of the same
 * class and returns true iff it is equal to the given value. If x.equals(y), then y.equals(x) must
 * also be true.
 *
 * The result of x.equals(y) is undefined if x and y are not the same class. Implementers can assume
 * y is the same type as x, and callers can use Core.equals to perform the proper test.
 */
export function eq(o: any) {
    return Is.object(o) && Is.func(o.equals);
}
export interface Equality {
    equals(other: Equality): number;
}

/**
 * An Identity object defines an `id` property that is either a number or a non-empty string. For
 * Identity objects x and y of the same class, x = y iff x.id = y.id.
 *
 * If an object Has.eq and Has.id, the functions must be consistent in their definition of equality.
 */
export function id(o: any) {
    if (!Is.object(o)) {
        return false;
    }
    var id = o.id;
    return Is.number(id, true) || (id && Is.string(id));
}
export interface Identity {
    id: string | number;
}

/**
 * A Hashable object defines a `hash` function that returns a deterministic String or Number. For
 * Hashable objects x and y, x = y implies x.hash() = y.hash(), but x.hash() = y.hash() does not
 * imply that x = y.
 *
 * If your object's `hash` function values do not collide, then you are free to rely on that
 * property, but it is wise to document the uniqueness of the hash so that future edits don't break
 * that assumption.
 */
export function hash(o: any) {
    return Is.object(o) && Is.func(o.hash);
}
export interface Hashable {
    hash(): string | number;
}

/**
 * A Comparable object defines a `compare` function that accepts a value of the same class and
 * returns a standard comparison value (negative, 0, positive) to indicate the proper order of the
 * two elements.
 *
 * The result of x.compare(y) is undefined if x and y are not the same class. Implementers can
 * assume y is the same type as x, and callers must ensure that Is.sameClass(x, y).
 */
export function cmp(o: unknown): o is Comparable {
    return Is.object(o) && "compare" in o && Is.func(o.compare);
}
export interface Comparable {
    compare(other: Comparable): number;
}

/**
 * A Keyed object defines a `key` function that returns a value to use for comparison. The type
 * returned from this function must be consistent.
 *
 * For example, if a Foo object should be compared by increasing size and then alphabetic color, it
 * could define the following:
 *
 *  Foo.prototype.key = function() {
 *      return [this.size, this.color];
 *  };
 *
 * As in the example above, arrays can be used as tuples for specifying values of decreasing
 * significance to use in the comparison. In this case, the key type is (Number, String) for all
 * Foos.
 *
 * Has.cmp typically overrides Has.key, so it doesn't make much sense to define both.
 */
export function key(o: unknown): o is Keyed<unknown> {
    return Is.object(o) && "key" in o && Is.func(o.key);
}
export interface Keyed<K> {
    key(): K;
}
