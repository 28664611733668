import * as Dom from "Everlaw/Dom";
import * as Tooltip from "Everlaw/UI/Tooltip";
import * as UI from "Everlaw/UI";
import * as Widget from "Everlaw/UI/Widget";
import eventUtil = require("dojo/_base/event");

/**
 * Base class for a widget with a boolean state.
 */
abstract class Toggle extends Widget {
    noToggleOnClick: boolean;
    tooltip: Tooltip;
    onTooltip: Dom.Content;
    offTooltip: Dom.Content;
    disabledReason: Dom.Content;
    forceInitTooltip: boolean;
    onClickCustom: () => void;
    /** Subclasses should call initToggle() during construction after the node has been created. */
    initToggle(set = false, disabled = false, makeAction = true) {
        if (this.onTooltip || this.offTooltip || this.disabledReason || this.forceInitTooltip) {
            this.tooltip = new Tooltip(this.node, this.isSet() ? this.onTooltip : this.offTooltip);
            this.registerDestroyable(this.tooltip);
        }
        makeAction && !this.noToggleOnClick && Dom.addClass(this, "action");
        this.setDisabled(disabled);
        this.set(set, true);
    }
    set(set: boolean, silent = false) {
        this._set(set, silent);
        this.updateTooltip(set, this.isDisabled());
        if (!silent) {
            this._onChange(set);
        }
    }
    /** Subclasses can override _set() to implement proper setting of the widget state. */
    protected _set(set: boolean, silent: boolean) {
        Dom.toggleClass(this.node, "selected", set);
    }
    /** Subclasses can override to provide the correct state check of the widget. */
    isSet() {
        return Dom.hasClass(this.node, "selected");
    }
    getValue() {
        return this.isSet();
    }
    toggle(silent?: boolean) {
        this.set(!this.isSet(), silent);
    }
    protected onClick(evt: Event) {
        eventUtil.stop(evt);
        if (!this.isDisabled() && !this.noToggleOnClick) {
            this.toggle();
        }
        this.onClickCustom?.();
    }
    onChange(state: boolean, me: Toggle) {}
    /** Subclasses can override _onChange(), but it should always call onChange(). */
    protected _onChange(state: boolean) {
        this.onChange(state, this);
    }
    setDisabled(disabled: boolean, onlyShowDisabledText = false) {
        this._setDisabled(disabled);
        this.updateTooltip(this.isSet(), disabled, onlyShowDisabledText);
    }
    /** Subclasses can override _setDisabled() to implement appropriate disabling of the widget. */
    protected _setDisabled(disabled: boolean) {
        UI.toggleDisabled(this.node, disabled);
    }
    /** Subclasses can override to provide the correct disabled check of the widget. */
    isDisabled() {
        return Dom.hasAttr(this.node, "disabled");
    }
    protected updateTooltip(set: boolean, disabled: boolean, onlyShowDisabledText = false) {
        if (this.tooltip) {
            if (disabled && this.disabledReason) {
                const tooltipForCurrentState = set ? this.onTooltip : this.offTooltip;
                if (!tooltipForCurrentState || onlyShowDisabledText) {
                    Dom.setContent(this.tooltip, this.disabledReason);
                } else {
                    Dom.setContent(
                        this.tooltip,
                        tooltipForCurrentState + " (disabled - " + this.disabledReason + ")",
                    );
                }
            } else {
                Dom.setContent(this.tooltip, set ? this.onTooltip : this.offTooltip);
            }
        }
    }
}

module Toggle {
    export interface Params {
        /** If true, the toggle will be disabled at creation. */
        disabled?: boolean;
        /** If true, the toggle will be set at creation. */
        state?: boolean;
        /** If true, the state can only be changed programmatically. Not supported by all toggles. */
        noToggleOnClick?: boolean;
        /** The tooltip to show when the toggle is set. */
        onTooltip?: Dom.Content;
        /** The tooltip to show when the toggle is not set. */
        offTooltip?: Dom.Content;
        /** If specified, will be added to the tooltip when the toggle is disabled. */
        disabledReason?: Dom.Content;
        /**
         * If true, initialize a tooltip regardless of whether its content is specified in other
         * params. This allows lazily setting the content of the tooltip later.
         */
        forceInitTooltip?: boolean;
        /** Callback for toggle click. */
        onClickCustom?: () => void;
        /** Callback for toggle state change. */
        onChange?: (state: boolean, me: Toggle) => void;
    }
}

export = Toggle;
