/*
This file should import nothing because it may be used outside the platform.
 */

/**
 * {@link Element} attribute that can be used to identify classes of Elements for purposes outside
 * of styling (e.g. analytics, e2e testing, etc.). There can be multiple elements with the same
 * EverClass on a page at the same time.
 */
const EVERCLASS_ATTRIBUTE = "data-everclass";

/**
 * Object containing all EverClasses. EverClasses are used to identify classes of Elements for
 * purposes outside of styling e.g. analytics, e2e testing, etc. There can be multiple elements with
 * the same EverClass on a page at the same time.
 *
 * All properties/constants defined in this object should be in alphabetical order.
 */
export const EVERCLASS = {
    BASE_SELECT: {
        BASE_SELECT: "BaseSelect",
        OPTION: "BaseSelect-option",
    },
    /**
     * EverClasses associated with Bluebook design system components.
     */
    BB: {
        TAG: "Bluebook-Tag",
    },
    CARD: {
        BINDER: "binder-card",
        CONTENT: "card-content",
    },
    CODING_FULL_SCREEN: {
        BADGE_SECTION: "BadgeSection",
    },
    DIALOG: {
        DIALOG: "Dialog",
        TITLE: "Dialog-title",
    },
    FULL_SCREEN: {
        DND_SECTION: {
            CONTENT: "DndSection-content",
        },
        MODULE_GROUP: {
            TAB_BAR: "FullScreen-ModuleGroup-Bar",
        },
    },
    GRID: {
        ESI_PAGE: "grid-esi-page",
    },
    HOMEPAGE: {
        FOLDERS: {
            NAME: "homepage-folder-name",
        },
    },
    POPOVER_MENU: "popover-menu",
    /**
     * EverClasses associated with elements on the Projects & Users tab.
     */
    PROJECTS_USERS_TAB: {
        PROJECTS_TABLE: "ProjectsAndUsers-projects-table",
        USERS_TABLE: "ProjectsAndUsers-users-table",
    },
    QUERY_DIALOG: "QueryDialog",
    RENAMEABLE: {
        EDITABLE_CONTENT: "Renameable-editable-content",
    },
    SCROLL_VIEWER: {
        TOMBSTONE: "scroll-viewer-tombstone",
    },
    SEARCH_TERM_REPORT: {
        DT_SEARCH_DETECTED: "SearchTermReport-dt-search-detected",
        LIST_ITEM: "SearchTermReport-list-item",
        SEARCH_TERM_HITS: "SearchTermReport-hits-cell",
        SEARCH_TERM_ROW: "SearchTermReport-term-row",
        TOOLBAR: {
            COPY: "SearchTermReport-copy",
            CREATE_HIGHLIGHTS: "SearchTermReport-create-highlights",
            DELETE: "SearchTermReport-delete",
        },
    },
    SEARCH_TERMS: {
        CONTENT: "SearchTerms-content",
        TERM: "SearchTerms-term",
    },
    STORYBUILDER: {
        TRANSCRIPT_VIEWER: {
            CONTENT: "transcript-viewer-content",
        },
    },
    VIEW_MANAGER: {
        FULLSCREEN_BUTTON: "ViewManager-fullscreenButton",
        OPTIONS: "ViewManager-options",
    },
    VISUALIZATION: {
        SVG_WRAPPER: "datavis-svg-wrapper",
    },
} as const;

type EverAttr = string | { [k: string]: EverAttr };
type RecursiveStringValuesOf<T extends EverAttr> =
    T extends Exclude<EverAttr, string> ? RecursiveStringValuesOf<T[keyof T]> : T;

/**
 * A type that includes all valid EverClass values as defined in {@link EVERCLASS}.
 */
export type EverClass = RecursiveStringValuesOf<typeof EVERCLASS>;

/**
 * Adds the given EverClass to the given {@link Element}.
 * @param element Element to add {@link everClass} to.
 * @param everClass EverClass to add to {@link element}.
 */
export function addEverClass<E extends Element>(element: E, everClass: EverClass): E {
    const everClasses = (element.getAttribute(EVERCLASS_ATTRIBUTE) || "").split(/\s+/);
    everClasses.push(everClass);
    element.setAttribute(EVERCLASS_ATTRIBUTE, everClasses.join(" "));
    return element;
}

/**
 * Constructs a selector string for a given {@link everClass}. This can be passed to anything
 * expecting a CSS selector.
 * @param everClass EverClass to construct a selector string for.
 */
export function everClassSelector(everClass: EverClass): string {
    return `[${EVERCLASS_ATTRIBUTE}~="${everClass}"]`;
}

/**
 * Generates an object containing an EverClass that can be spread (`...`) as props into a TSX/React
 * element or attribute object. If no {@link everClass} is provided, returns an empty object.
 * @param everClass EverClass to assign as a prop.
 */
export function everClassProp(everClass?: EverClass): Record<string, EverClass> {
    return everClass === undefined ? {} : { [EVERCLASS_ATTRIBUTE]: everClass };
}
