/**
 * Utility functions for testing variable types
 *
 * This module should never have dependencies, as it is used everywhere else in our system.
 */

/**
 * Our in-house style prefers:
 *
 *  Is.defined(v)
 *  ! Is.defined(v)
 *
 * to:
 *
 *  v !== undefined
 *  v === undefined
 *
 * Note that this will not work for a {@link v} that may not be defined in the current scope! In
 * such cases, you will have to implement the following inline (as in the definition of
 * `Is.WEB_WORKER`).
 */
export function defined<T>(v: T): v is Exclude<T, undefined> {
    return typeof v !== "undefined";
}

export function string(s: unknown): s is string {
    return typeof s === "string";
}

export function boolean(b: unknown): b is boolean {
    return typeof b === "boolean";
}

/**
 * Returns true if {@link n} is a (non-NaN) number or, when strict is false (the default), a string
 * that can be converted to one.
 */
export function number(n: unknown, strict = false): boolean {
    if (num(n)) {
        return !isNaN(n);
    }
    return !strict && string(n) && n !== "" && !isNaN((n as never) - 0);
}

/**
 * Returns true if {@link n} is a number (including NaN).
 */
export function num(n: unknown): n is number {
    return typeof n === "number";
}

export function bigint(n: unknown): n is bigint {
    return typeof n === "bigint";
}

/**
 * Returns true iff {@link n} is literally NaN. This function is necessary because:
 *  - NaN === NaN returns false
 *  - isNaN("foo") returns true
 */
export function nan(n: unknown): boolean {
    return num(n) && isNaN(n);
}

/**
 * Returns true iff {@link n} is a whole integer in the range [-2^31..2^31-1].
 */
export function int(n: unknown): boolean {
    return num(n) && (n | 0) === n;
}

export function positiveInt(n: unknown): boolean {
    return num(n) && int(n) && n > 0;
}

export function percentage(n: unknown): boolean {
    return num(n) && n >= 0 && n <= 1;
}

/**
 * Returns true if {@link f} is a function. Makes no guarantee about shape of {@link f} if it is a
 * function.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function func(f: unknown): f is Function {
    return typeof f === "function";
}

/**
 * Returns true if {@link a} is an array.
 */
export function array(a: unknown): a is Array<any> {
    return a instanceof Array;
}

/**
 * Returns true if {@link o} is a non-null, non-function object.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function object<T>(o: T): o is Exclude<T & object, Function> {
    return o !== null && typeof o === "object";
}

/**
 * Returns true iff {@link o} is a simple Object, which is typically constructed with literal
 * syntax:
 * - Is.plainObject({})             // true
 * - Is.plainObject(new Object())   // true, but nonstandard
 * - Is.plainObject([])             // false
 * - Is.plainObject(document.body)  // false
 * - Is.plainObject(null)           // null
 * - Is.plainObject(undefined)      // false
 */
export function plainObject(o: unknown): boolean | null {
    return typeof o === "object" && o && Object.getPrototypeOf(o) === Object.prototype;
}

/**
 * Returns true iff {@link x} and {@link y} are two objects that are the same class, based on their
 * className (if present), or their `constructor` property.
 */
export function sameClass(x: unknown, y: unknown): boolean {
    if (!(object(x) && object(y))) {
        return false;
    }
    const xObj = x as Record<string, unknown>;
    const yObj = y as Record<string, unknown>;
    return xObj.className || yObj.className
        ? xObj.className === yObj.className
        : func(xObj.constructor) && xObj.constructor === yObj.constructor;
}

/**
 * True iff this is running in a Web Worker.
 */
export const WEB_WORKER = typeof window === "undefined";
