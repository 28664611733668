import * as Bugsnag from "Everlaw/Bugsnag";
import Button = require("Everlaw/UI/Button");
import dojo_on = require("dojo/on");
import Dom = require("Everlaw/Dom");
import Input = require("Everlaw/Input");
import Is = require("Everlaw/Core/Is");
import Util = require("Everlaw/Util");

/*
 * This widget is intended to be used in concert with the Validated widgets which extend the
 * ValidatedSubmitForm interface. This widget automatically disables/enables itself when the
 * associated forms are valid or invalid. It also will focus on the first invalid form, alter
 * onSubmit behavior of its forms to focus on the subsequent form or trigger the onClick behavior
 * of the validated submit if all the forms are valid.
 * Any situation where you want a button that's enabled/disabled based on the contents of some input
 * should use this widget.
 * Note: You can create a new widget using any type of input that implements ValidatedSubmitForm
 * if you want to couple it with some type of select or other type of input that doesn't exist in
 * Validated.ts yet.
 */
export class ValidatedSubmit {
    submitButton: Button;
    private validatedForms?: ValidatedSubmitForm[];
    private allValidated: boolean;
    private submitAction;
    private node: HTMLElement;
    private buttonDiv: HTMLElement;
    private doNotConnect: boolean | undefined;
    private toDestroy: Util.Destroyable[] = [];

    constructor(params: ValidatedSubmitParams) {
        this.doNotConnect = params.doNotConnect;
        this.submitAction = params.buttonParams.onClick;
        params.buttonParams.onClick = (e) => {
            this.validateAndSubmit(e);
        };
        this.submitButton = new Button(params.buttonParams);
        this.toDestroy.push(this.submitButton);
        this.buttonDiv = Dom.div({ class: "validated-submit-button-div" });
        this.node = Dom.div(
            { class: "validated-submit-div" },
            Dom.node(this.submitButton),
            this.buttonDiv,
        );
        if (params.class) {
            Dom.addClass(this.node, params.class);
        }
        if (params.buttonParams.parent) {
            Dom.place(this.node, params.buttonParams.parent);
        }
        this.toDestroy.push(
            dojo_on(this.buttonDiv, Input.tap, (e) => {
                this.validateAndSubmit(e);
            }),
        );
        this.toDestroy.push(
            dojo_on(this.buttonDiv, "keydown", (e: Event) => {
                if (e instanceof KeyboardEvent && e.code === "Enter") {
                    this.validateAndSubmit(e);
                }
            }),
        );

        this.validatedForms = params.forms;
        if (this.validatedForms?.some((form) => !form)) {
            // Log an error when given null/undefined forms.
            // For investigating https://podio.com/easyesicom/feature-roadmap/apps/bugs/items/28772
            Bugsnag.notify(Error("Invalid forms"));
        }

        this.monitorForms(this.validatedForms || []);
        this.setDisabled(true);
        this.connectAndEnable();
    }

    getNode() {
        return this.node;
    }
    getSubmitButton() {
        return this.submitButton;
    }

    addFormToValidated(form: ValidatedSubmitForm, index?: number) {
        this.addFormsToValidated([form], index);
    }

    addFormsToValidated(forms: ValidatedSubmitForm[], index?: number) {
        if (forms.some((form) => !form)) {
            // Log an error when given null/undefined forms.
            // For investigating https://podio.com/easyesicom/feature-roadmap/apps/bugs/items/28772
            Bugsnag.notify(Error("Invalid forms"));
        }
        if (this.validatedForms) {
            if (Is.defined(index) && Is.number(index) && index < this.validatedForms.length) {
                this.validatedForms.splice(index, 0, ...forms);
            } else {
                this.validatedForms = this.validatedForms.concat(forms);
            }
        } else {
            this.validatedForms = forms;
        }
        this.monitorForms(forms);
        this.connectAndEnable();
    }

    removeForm(form: ValidatedSubmitForm) {
        if (this.validatedForms) {
            this.validatedForms = this.validatedForms.filter((f) => f !== form);
            this.connectAndEnable();
        }
    }

    indexOfForm(form: ValidatedSubmitForm) {
        if (this.validatedForms) {
            return this.validatedForms.indexOf(form);
        }
    }

    private connectAndEnable() {
        !this.doNotConnect && this.connectSubmits();
        this.validateAndEnable(this);
    }

    private monitorForms(forms: ValidatedSubmitForm[]) {
        forms && forms.forEach((form) => this.monitorForm(form));
    }

    /**
     * Sets each form's input to report its status onChange so that the submit button can be
     * enabled/disabled while the user is typing.
     */
    private monitorForm(form: ValidatedSubmitForm) {
        if (form) {
            const newChange = () => {
                const isValid = form.isValid();
                this.validateIndividual(this, isValid);
            };
            form.subscribeToChanges(newChange);
        }
    }

    /**
     * On submit each form links to the next form while the last form links to the final submit
     * action if all the forms are valid.
     */
    private connectSubmits() {
        if (this.validatedForms && this.validatedForms.length >= 1) {
            for (let i = 0; i < this.validatedForms.length - 1; i++) {
                const intermediateSubmit = () => this.validatedForms?.[i + 1].focus();
                this.validatedForms[i].addToSubmit(intermediateSubmit);
            }
            const finalSubmit = (e: Event) => this.validateAndSubmit(e);
            this.validatedForms[this.validatedForms.length - 1].addToSubmit(finalSubmit);
        }
    }

    setDisabled(disable: boolean) {
        Dom.show(this.buttonDiv, disable && !this.allValidated);
        this.submitButton.setDisabled(disable);
    }

    private validateAndEnable(me: ValidatedSubmit) {
        me.allValidated = !me.validate();
        me.setDisabled(!me.allValidated);
    }

    private validateIndividual(me: ValidatedSubmit, isValid: boolean) {
        // If isValid is different from all validated then a state change is needed.
        if (isValid !== me.allValidated) {
            // if isValid is false then we know that not all are valid so we change the style
            if (!isValid) {
                me.allValidated = isValid;
                me.setDisabled(!isValid);
                // if isValid is true and allValidated is false then we need to check all of the forms
                // to see if allValidated should be turned to true.
            } else {
                me.validateAndEnable(me);
            }
        }
    }

    /**
     * If all the forms are valid then submit, otherwise focus on the first invalid form.
     */
    private validateAndSubmit(e: Event) {
        if (this.allValidated) {
            this.submitAction?.(e, this.submitButton);
        } else {
            const validatedSubmitForm = this.validate();
            if (validatedSubmitForm) {
                validatedSubmitForm.focus();
            } else {
                // validate returned early which means there are no validated forms
                this.submitAction?.(e, this.submitButton);
            }
        }
    }

    private validate() {
        if (!this.validatedForms || this.validatedForms.length === 0) {
            this.allValidated = true;
            return;
        }
        let invalidForm: ValidatedSubmitForm | undefined = undefined;
        for (const form of this.validatedForms) {
            if (!form.isValid()) {
                invalidForm = form;
                break;
            }
        }
        return invalidForm;
    }

    focusOnFirst() {
        if (this.validatedForms && this.validatedForms.length >= 1) {
            this.validatedForms[0].focus();
        }
    }

    focus() {
        this.submitButton.focus();
    }

    destroy() {
        Util.destroy(this.toDestroy);
    }
}

export interface ValidatedSubmitParams {
    forms?: ValidatedSubmitForm[];
    buttonParams: Button.Params;
    // If you don't want the onSubmit behavior of the forms to be altered.
    doNotConnect?: boolean;
    // Occasionally styling should be applied to the parent div and not the button itself.
    class?: string;
}

export interface ValidatedSubmitForm {
    isValid: () => boolean;
    focus: () => void;
    subscribeToChanges: (subscription: () => void) => void;
    addToSubmit: (submitLogic: (e?: any) => void) => void;
}
