/**
 * Defines various comparison functions for use in sorting based on the implicit interfaces in
 * Core/Has.
 *
 * The typed comparison functions do not handle undefined or null values. If that is needed, they
 * can be wrapped with Cmp.nullSafe.
 */
import Has = require("Everlaw/Core/Has");
import Is = require("Everlaw/Core/Is");

interface Cmp<X, Y = X> {
    (x: X, y: Y): number;
}
module Cmp {
    /**
     * Orders false before true.
     */
    export function bool(x: boolean, y: boolean) {
        // compare 0 or 1
        return Number(x) - Number(y);
    }

    /**
     * Provides a total ordering of numbers. Cmp.num(NaN, NaN) = 0, and Cmp.num(n, NaN) = -1 for all
     * numbers n != NaN.
     */
    export function num(x: number, y: number): -1 | 0 | 1 {
        if (x === y) {
            return 0;
        } else if (x < y) {
            return -1;
        } else if (x > y) {
            return 1;
        } else if (Is.nan(x)) {
            return Is.nan(y) ? 0 : 1;
        } else if (Is.nan(y)) {
            return -1;
        } else {
            throw new TypeError(`Non-number passed to number comparator. x: ${x}, y: ${y}`);
        }
    }

    // TODO: Use Intl.Collator in the Cmp.str functions where it's supported; see:
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare#Performance

    /**
     * Provides a total, natural ordering for strings. Guarantees that Cmp.str('a', 'Z') < 0 and
     * Cmp.str('a', 'A') != 0.
     */
    export var str =
        "a".localeCompare("Z") < 0
            ? function (x: string, y: string) {
                  // The browser's implementation of localeCompare does what we want.
                  return x.localeCompare(y);
              }
            : function (x: string, y: string) {
                  // Break case-insensitive ties with case-sensitive comparisons.
                  return strCI(x, y) || x.localeCompare(y);
              };

    const CASE_INSENSITIVE: Intl.CollatorOptions = { sensitivity: "base" };

    /**
     * Provides a case-insensitive ordering for strings. Cmp.strCI('a', 'A') = 0.
     */
    export var strCI =
        "a".localeCompare("A", undefined, CASE_INSENSITIVE) === 0
            ? function (x: string, y: string) {
                  // The browser provides extended locale compare support that does what we want.
                  return x.localeCompare(y, undefined, CASE_INSENSITIVE);
              }
            : function (x: string, y: string) {
                  if (x === y) return 0;
                  // We have to normalize the case. Proper German sorting requires converting to uppercase, not
                  // lowercase. See https://en.wikipedia.org/wiki/German_orthography#Sorting, which indicates that
                  // "Eszett" must be treated as ss when sorting. Converting it to uppercase results in "SS".
                  return x.toLocaleUpperCase().localeCompare(y.toLocaleUpperCase());
              };

    /**
     * Provides a string ordering that puts strings that differ only by quotes near each other.
     */
    export function strQuotes(x: string, y: string) {
        return str(x.replace(/"/g, ""), y.replace(/"/g, "")) || str(x, y);
    }

    export function forArr<T>(cmp: Cmp<T>) {
        return function (x: T[], y: T[]) {
            return arr(x, y, cmp);
        };
    }

    /**
     * Provides a full, deep ordering for arrays. If the arrays are semantically typed tuples, then
     * `Cmp.forTuple(comparisons...)` should be preferred.
     *
     * Comparison proceeds element-wise. If the end of one array is reached before the other, with
     * everything up to that point comparing the same, then the shorter array comes first.
     */
    export function arr<T>(x: T[], y: T[], cmp: Cmp<T>): number;
    export function arr<T extends Full>(x: T[], y: T[]): number;
    export function arr<T extends Full>(x: T[], y: T[], cmp: Cmp<T> = full) {
        for (var i = 0, l = Math.min(x.length, y.length); i < l; i++) {
            var c = cmp(x[i], y[i]);
            if (c) {
                return c;
            }
        }
        // All elements compared equal. Shortest array comes first.
        return x.length - y.length;
    }

    /**
     * Compares objects implementing Has.cmp. Assumes that Is.sameClass(x, y).
     */
    export function cmp<T extends Has.Comparable>(x: T, y: T) {
        return x.compare(y);
    }

    /**
     * Returns a comparison function(x, y) that calls `keyCmp(key(x), key(y))`. If the `key` argument is
     * omitted, then each element must implement the Has.key interface.
     *
     * The `keyCmp` argument is required. If you don't know what type to expect from `key`, use
     * Cmp.full. Calling `Cmp.forKey(Cmp.full)` (with no `key`) produces a comparator that is
     * semantically the same as `Cmp.key`.
     */
    export function forKey<K>(keyCmp: Cmp<K>): Cmp<Has.Keyed<K>>;
    export function forKey<K, T>(keyCmp: Cmp<K>, key: (o: T) => K): Cmp<T>;
    export function forKey(keyCmp: any, key = (o: Has.Keyed<any>) => o.key()) {
        return function (x: any, y: any) {
            return keyCmp(key(x), key(y));
        };
    }

    /**
     * Compares implementors of Has.key. Assumes that Is.sameClass(x, y). If the type produced by the
     * key is known, then Cmp.forKey(keyTypeCmp) should be preferred.
     */
    export function key(x: Has.Keyed<Full>, y: Has.Keyed<Full>) {
        return full(x.key(), y.key());
    }

    /**
     * Compares implementors of Has.id. Assumes that Is.sameClass(x, y).
     */
    export function id(x: Has.Identity, y: Has.Identity) {
        return full(x.id, y.id);
    }

    /**
     * Returns a comparator that handles undefined and null values, delegating to cmp only when both
     * arguments are neither undefined nor null. This ordering does not distinguish undefined from null.
     *
     * The nullLast parameter, which defaults to false, indicates whether null values should be sorted
     * to the beginning or the end.
     */
    export function nullSafe<T>(cmp: Cmp<T>, nullLast?: boolean): Cmp<T> {
        var edge = nullLast ? -1 : 1;
        return function (x: T, y: T) {
            return x == null ? (y == null ? 0 : -edge) : y == null ? edge : cmp(x, y);
        };
    }

    /** Union of types which may be compared with Cmp.full. */
    export type Full = boolean | number | string | FullArray | Has.Comparable | FullKeyed;
    export function isFull(x: unknown): x is Full {
        if (Is.boolean(x) || Is.num(x) || Is.string(x) || Has.cmp(x)) {
            return true;
        } else if (Is.array(x)) {
            return x.every(isFull);
        } else if (Has.key(x) && isFull(x.key())) {
            return true;
        }
        return false;
    }
    interface FullArray extends Array<Full> {}
    interface FullKeyed extends Has.Keyed<Full> {}

    /**
     * Compares all values that we currently support. It's faster, safer, and clearer to sort by an
     * appropriately-typed comparison function, so this function should only be used when there's no
     * other reasonable option.
     *
     * The comparison order is:
     *
     *  - undefined/null (not differentiated from one another)
     *  - false
     *  - true
     *  - numbers, NaN last
     *  - strings, case-insensitively
     *  - arrays, compared element-wise
     *  - objects:
     *      - same class:
     *          - x.compare(y)
     *          - compare(x.key(), y.key())
     *          - compare(x.id, y.id)
     *      - different classes, by the className property (if it exists)
     *  - everything that falls through is compared using < and >
     */
    export var full = (function () {
        // First try to order values according to these types, using the indicated comparison.
        var types: [(val: any) => boolean, Cmp<any>][] = [
            [Is.boolean, bool],
            [Is.num, num],
            [Is.string, str],
            [Is.array, arr],
        ];
        // Then, if the objects are the same class, order according to the implemented interface.
        var impls: [(obj: any) => boolean, Cmp<any>][] = [
            [Has.cmp, cmp],
            [Has.key, key],
            [Has.id, id],
        ];
        return nullSafe<Full>(function (x: any, y: any) {
            for (var test_cmp of types) {
                var test = test_cmp[0];
                if (test(x)) {
                    if (test(y)) {
                        return test_cmp[1](x, y);
                    }
                    return -1;
                }
                if (test(y)) {
                    return 1;
                }
            }
            if (Is.sameClass(x, y)) {
                for (var iface_cmp of impls) {
                    if (iface_cmp[0](x)) {
                        return iface_cmp[1](x, y);
                    }
                }
            } else if (x && x.className && y && y.className) {
                // Order based on our EBO class names.
                return str(x.className, y.className);
            }
            // Fallback to normal Javascript comparison.
            var c = x < y ? -1 : x > y ? 1 : 0;
            return c;
        });
    })();

    /**
     * Returns a function for tuple comparison. The arguments to `forTuple` specify the comparison to
     * use for each position in the tuple. The main use case is to allow a key function to return a
     * short array specifying successively-less-important properties to compare.
     *
     * For example, to sort objects by their `name` and break ties with their `id`, you can call:
     *
     *      Arr.sort(namedIdObjs, Cmp.forKey(Cmp.forTuple(Cmp.str, Cmp.num), function(o) {
     *          return [o.name, o.id];
     *      });
     *
     * The implementation assumes that the types of the tuple elements match the types passed in. The
     * behavior is undefined if this property does not hold.
     */
    export function forTuple<A>(cmp1: Cmp<A>): Cmp<[A]>;
    export function forTuple<A, B>(cmp1: Cmp<A>, cmp2: Cmp<B>): Cmp<[A, B]>;
    export function forTuple<A, B, C>(cmp1: Cmp<A>, cmp2: Cmp<B>, cmp3: Cmp<C>): Cmp<[A, B, C]>;
    export function forTuple<A, B, C, D>(
        cmp1: Cmp<A>,
        cmp2: Cmp<B>,
        cmp3: Cmp<C>,
        cmp4: Cmp<D>,
    ): Cmp<[A, B, C, D]>;
    // Add more overloads if necessary
    export function forTuple() {
        var cmps = arguments;
        return function (x: any[], y: any[]) {
            for (var i = 0; i < cmps.length; i++) {
                var c = cmps[i](x[i], y[i]);
                if (c) {
                    return c;
                }
            }
            return 0;
        };
    }
}
export = Cmp;
