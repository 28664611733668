import Dom = require("Everlaw/Dom");
import Is = require("Everlaw/Core/Is");
import Toggle = require("Everlaw/UI/Toggle");
import Tooltip = require("Everlaw/UI/Tooltip");
import { FocusDiv, makeFocusable } from "Everlaw/UI/FocusDiv";
import * as Input from "Everlaw/Input";

/**
 * Base checkbox class using an HTML input element.
 */
class Checkbox extends Toggle {
    labelNode: HTMLLabelElement;
    protected checkbox: HTMLInputElement;
    protected onUserClick: (state: boolean, me: Checkbox) => void;
    private preventDefault: boolean;
    private focusDiv: FocusDiv | null = null;
    private readonly screenReaderAdapted: boolean;
    constructor(params: Checkbox.Params) {
        super();
        Object.assign(this, params);
        const makeWrapper = params.blockDisplay ? Dom.div : Dom.span;
        this.node = makeWrapper(
            { class: ["checkbox", params.styleClass].join(" ").trim() },
            (this.checkbox = Dom.create("input", {
                type: "checkbox",
                checked: params.state || false,
                tabIndex: params.tabIndex || -1,
                id: this.id,
                disabled: params.disabled || false,
            })),
            (this.labelNode = Dom.label(
                {
                    for: this.id,
                    class:
                        (params.right ? "right-label " : "")
                        + (params.ellipsed ? "ellipsed-label " : ""),
                },
                params.label || "\u200B",
            )), // zero-width space (need text for vertical alignment)
        );
        params.labelWidth && Dom.style(this.labelNode, "width", params.labelWidth + "px");
        if (!Is.defined(params.makeFocusable) || params.makeFocusable) {
            this.focusDiv = makeFocusable(this.labelNode, "focus-label-style", params.focusDivPos);
            this.registerDestroyable([
                this.focusDiv,
                Input.fireCallbackOnKey(this.focusDiv.node, [Input.SPACE, Input.ENTER], (e) => {
                    // Dijit allows Enter to be used to select checkboxes, but that doesn't follow
                    // our standards (which are pulled from more widely-used standards), so we
                    // disable that interaction here.
                    if (e.key === Input.ENTER) {
                        e.stopPropagation();
                        return;
                    }
                    // The order of events and state-changes on Checkboxes can be confusing because
                    // we don't expose the default input element to clicks. The logic below makes
                    // sure the visual checkbox state is consistent with its internal state.
                    if (!this.isDisabled()) {
                        const oldVal = this.checkbox.checked;
                        this.checkbox.click();
                        if (oldVal === this.checkbox.checked) {
                            this.checkbox.checked = !this.checkbox.checked;
                        }
                        if (this.isIndeterminate()) {
                            this.setIndeterminate(false);
                        }
                        setTimeout(() => this.focusDiv?.focus(), 100);
                    }
                    e.preventDefault();
                    e.stopPropagation();
                }),
                () => {
                    this.focusDiv = null;
                },
            ]);
        }
        if (params.ellipsed) {
            const tooltip = new Tooltip.MirrorTooltip(
                this.labelNode,
                this.labelNode,
                params.tooltipPosition || ["below-centered"],
            );
            this.registerDestroyable(tooltip);
        }
        if (params.adaptForScreenReader) {
            // This disassociates the styled label from the actual checkbox input.
            //  This is a workaround for a bug where screen reader selections of a checkbox
            //  would result in a double-flipping of the checkbox, ending up with the state
            //  not actually changed. This is the same reason for the this.isDisabled() code fork
            //  in the keyboard input callback function above).
            Dom.removeAttr(this.labelNode, "for");

            // Now that the label has been disassociated from the checkbox itself, this role allows
            //  screen readers to interpret the label itself as a checkbox, which matches
            //  what we're doing for sighted users too - hiding the input element and displaying
            //  the styled label only.
            Dom.setAttr(this.labelNode, "role", "checkbox");

            // Although we already set the input element's tabindex to -1, screen readers are still
            //  able to navigate to the input element. This hides the input element so that screen
            //  readers only can navigate to the styled label, matching the perception of checkboxes
            //  for sighted users.
            Dom.setAttr(this.checkbox, "aria-hidden", "true");

            // This specifies to browsers that this element should be focusable. It's required to
            //  implement a styled, screen-reader-friendly checkbox from scratch (like we're doing
            //  here).
            Dom.setAttr(this.labelNode, "tabindex", "0");

            // Initializes the aria-checked value to the current checkbox state.
            this.updateAriaChecked();
        }
        this.screenReaderAdapted = params.adaptForScreenReader || false;
        this.initToggle(params.state, params.disabled, false);
        // Bind to onclick rather than onchange! It handles some corner cases better, and is still
        // triggered by keyboard.
        this.connect(this.checkbox, "click", (evt) => this.onClick(evt));
        params.parent && Dom.place(this, params.parent);
    }
    /**
     * Returns the underlying input element. In general, appropriate class methods should be used to
     * test/set the element's state, however there are a few places in our codebase where we need
     * access to the element itself. Making the input protected and providing this getter hopefully
     * provides a sanity check and warning to the caller.
     */
    getInputElem(): HTMLInputElement {
        return this.checkbox;
    }

    /**
     * Add a new onclick event handler in addition to the current ones.
     * @param newHandler
     */
    addOnclickHandler(newHandler: (state: boolean, me: Checkbox) => void) {
        const oldOnUserClick = this.onUserClick;
        this.onUserClick = (state: boolean, me: Checkbox) => {
            oldOnUserClick(state, me);
            newHandler(state, me);
        };
    }
    override focus(): void {
        this.checkbox.focus();
    }
    protected override onClick(evt: Event): void {
        this.set(this.checkbox.checked, false);
        this.onUserClick && this.onUserClick(this.checkbox.checked, this);
        this.preventDefault && evt.preventDefault();
    }
    protected override _set(set: boolean, silent: boolean): void {
        this.checkbox.checked = set;
        this.checkbox.indeterminate = false;
        if (this.screenReaderAdapted) {
            this.updateAriaChecked();
        }
    }
    /** Returns the current checkbox state. Returns null if the checkbox is indeterminate. */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore The fact that this method can return null means that it's not a proper override
    // for Toggle's isSet() method, which is supposed to return a boolean. Unfortunately, there is
    // no easy fix for this because the Checkbox class is used in so many places. Hopefully, this
    // isn't much of an issue in practice because there shouldn't be too many places where we want
    // to treat checkboxes like toggles.
    override isSet(): boolean | null {
        return this.checkbox.indeterminate ? null : this.checkbox.checked;
    }
    isIndeterminate(): boolean {
        return this.checkbox.indeterminate;
    }
    /**
     * Set the checkbox state to indeterminate. Does not trigger any onChange callbacks. (When
     * indeterminate is true, it overrides the current checked state of the element.)
     * */
    setIndeterminate(set: boolean): void {
        this.checkbox.indeterminate = set;
        if (this.screenReaderAdapted) {
            this.updateAriaChecked();
        }
    }
    protected override _setDisabled(disabled = true): void {
        this.checkbox.disabled = disabled;
        Dom.toggleClass(this.node, "disabled", disabled);
    }
    override isDisabled(): boolean {
        return this.checkbox.disabled;
    }
    setLabel(label: string): void {
        Dom.setContent(this.labelNode, label);
        this.focusDiv && this.focusDiv.replace();
    }
    setAriaLabel(label: string): void {
        Dom.setAttr(this.labelNode, "aria-label", label);
    }
    setAriaLive(liveValue: string): void {
        Dom.setAttr(this.labelNode, "aria-live", liveValue);
    }
    private updateAriaChecked(): void {
        Dom.setAttr(
            this.labelNode,
            "aria-checked",
            this.checkbox.indeterminate ? "mixed" : this.checkbox.checked.toString(),
        );
    }
}

module Checkbox {
    export interface Params extends Toggle.Params {
        /** The label associated with the checkbox. */
        label?: Dom.Content;
        /** If the label should be ellipsed or not.  If so, there will be a tooltip. */
        ellipsed?: boolean;
        /** If ellipsed, the dojo position string array to decide where to place the tooltip.
         *  Defaults to ["below-centered"] */
        tooltipPosition?: string[];
        /** Where the checkbox should be placed. */
        parent?: Dom.Nodeable | Node;
        /** Whether the box should be on the right side (default false). */
        right?: boolean;
        /** For direct user clicks, i.e. for GA. */
        onUserClick?: (state: boolean, me: Checkbox) => void;
        /** If true, we'll call preventDefault() on every click event on the checkbox. */
        preventDefault?: boolean;
        tabIndex?: number;
        /** Whether the box should display as a block-level element. */
        blockDisplay?: boolean;
        /**
         * Whether to make the checkbox focusable. Defaults to true; generally all checkboxes should be
         * focusable.
         */
        makeFocusable?: boolean;
        focusDivPos?: string;
        labelWidth?: number;
        /**
         * Whether to take extra steps to make this Checkbox more screen-reader friendly.
         * The preferred way to add a descriptive label to any <input> element is to use the <label>
         * tag. However, if for any reason that is not possible, mark this as true to perform a set of
         * changes to the Checkbox during construction that relies on ARIA attributes for checkbox
         * labeling. Since using a <label> tag is preferred over using this param, this defaults to
         * false.
         * Details on these specific changes are in Checkbox's constructor, since there are other
         * subclasses that use or extend Checkbox.Params.
         */
        adaptForScreenReader?: boolean;
        /**
         * Custom style class for wrapper node. This does not replace the default styling.
         */
        styleClass?: string;
    }

    /**
     * A visual variant on the standard checkbox. Does not support being put in the
     * indeterminate state.
     */
    export function asToggleSlider(params: Checkbox.Params) {
        const cb = new Checkbox(params);
        Dom.addClass(cb.getInputElem(), "toggle-slider");
        Dom.addClass(cb.getNode(), "toggle-checkbox");
        const focusContainer = Dom.div({ class: "toggle-focus-container" });
        Dom.place(focusContainer, cb, "last");
        Dom.place(Dom.div({ class: "toggle-background" }), focusContainer);
        return cb;
    }
}

export = Checkbox;
