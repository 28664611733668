import Bugsnag = require("Everlaw/Bugsnag");
import Is = require("Everlaw/Core/Is");
import { clamp } from "Everlaw/Util";
import { Colorable } from "design-system";
import { modulo } from "Everlaw/Util";

/* Color.ts is generated during the build.
 * If it's missing for you, try cleaning & building assets.
 */
import { ColorTokens, EverColor } from "design-system";

export type RandomColor =
    | typeof ColorTokens.RANDOM_1
    | typeof ColorTokens.RANDOM_2
    | typeof ColorTokens.RANDOM_3
    | typeof ColorTokens.RANDOM_4
    | typeof ColorTokens.RANDOM_5
    | typeof ColorTokens.RANDOM_6
    | typeof ColorTokens.RANDOM_7
    | typeof ColorTokens.RANDOM_8;

// If you change this array, any existing client colors (especially those derived from hashing ids)
// may change substantially!
export const RANDOM_COLORS: RandomColor[] = [
    ColorTokens.RANDOM_1,
    ColorTokens.RANDOM_2,
    ColorTokens.RANDOM_3,
    ColorTokens.RANDOM_4,
    ColorTokens.RANDOM_5,
    ColorTokens.RANDOM_6,
    ColorTokens.RANDOM_7,
    ColorTokens.RANDOM_8,
];

export function randomColorForId(id: number): RandomColor {
    return RANDOM_COLORS[id % RANDOM_COLORS.length];
}

/** Rgb(a) representation of a color */
export interface RgbaColorValues {
    r: number; // whole number, 0 <= r <= 255
    g: number; // whole number, 0 <= r <= 255
    b: number; // whole number, 0 <= r <= 255
    a: number; // fraction, 0 <= a <= 1
}

/** HSV representation of a color */
export interface HsvColorValues {
    h: number; // degree, 0 <= h <= 360
    s: number; // percentage, 0 <= s <= 100
    v: number; // percentage, 0 <= v <= 100
}

/** HSL representation of a color */
export interface HslColorValues {
    h: number; // degree, 0 <= h < 360
    s: number; // percentage, 0 <= s <= 100
    l: number; // percentage, 0 <= l <= 100
}

const BLACK_RGB = { r: 0, g: 0, b: 0 };

/**
 * Class representing an arbitrary color. Most of the time, you should not use this directly, as
 * colors are typically specified from our ColorTokens and typed as an EverColor (i.e., colors that
 * are officially part of our color palette). However, we occasionally use colors outside this
 * palette (e.g., for clustering, or when using hashes to create random colors for documents), so we
 * need a generic color representation.
 *
 * The constructor takes the Rgb(a) representation of the color. See the additional factory methods
 * for creating a Color instance from other types of data (EverColor or color token, hex string,
 * rgba string, etc.). Color.fromEverColor() is probably what you want the majority of the time.
 */
export class Color implements Colorable {
    private r: number;
    private g: number;
    private b: number;
    private a: number;
    constructor({ r, g, b, a = 1 }: { r: number; g: number; b: number; a?: number }) {
        this.setR(r);
        this.setG(g);
        this.setB(b);
        this.setA(a);
    }

    /**
     * Factory method to create a Color instance from an EverColor (including ColorTokens).
     */
    static fromEverColor(color: EverColor): Color {
        return Color.fromHexString(color);
    }

    /**
     * Factory method to create a Color instance from a hex string, e.g. "#00F" or "#0000FF".
     */
    static fromHexString(color: string): Color {
        let num = Number("0x" + color.substring(1));
        if ((color.length !== 4 && color.length !== 7) || isNaN(num)) {
            // TODO: throw here once we're confident that no current code is passing a bad value.
            // throw new Error("Invalid string passed to Color.fromHexString: " + color);
            Bugsnag.notify(Error("Invalid string passed to Color.fromHexString: " + color));
            return new Color(BLACK_RGB);
        }
        const bits = color.length === 4 ? 4 : 8;
        const mask = (1 << bits) - 1;
        const getNextColor = () => {
            const c = num & mask;
            num >>= bits;
            return bits === 4 ? 0x11 * c : c;
        };
        const b = getNextColor();
        const g = getNextColor();
        const r = getNextColor();
        return new Color({ r, g, b, a: 1 });
    }

    /**
     * Factory method to create a Color instance from a css rgb(a) string, e.g.
     * "rgb(0, 0, 255)" or "rgba(0, 0, 255, 0.5)".
     */
    static fromCssString(color: string): Color {
        const onInputError = () => {
            // TODO: throw here once we're confident that no current code is passing a bad value.
            // throw new Error("Invalid string passed to Color.fromRgbString: " + color);
            Bugsnag.notify(Error("Invalid string passed to Color.fromRgbString: " + color));
        };
        const m = color.toLowerCase().match(/^rgba?\(([\s.,0-9]+)\)/);
        if (!m) {
            onInputError();
            return new Color(BLACK_RGB);
        }
        const rgbaArr = m[1].split(/\s*,\s*/);
        if (rgbaArr.length < 3 || rgbaArr.length > 4) {
            onInputError();
            return new Color(BLACK_RGB);
        }
        return new Color({
            r: Number(rgbaArr[0]),
            g: Number(rgbaArr[1]),
            b: Number(rgbaArr[2]),
            a: rgbaArr.length === 4 ? Number(rgbaArr[3]) : 1,
        });
    }

    /**
     * Factory method to create a Color instance from hsv values (e.g. [240, 100, 100]) and an
     * optional alpha value.
     */
    static fromHsv({ h, s, v }: HsvColorValues, alpha = 1): Color {
        const hue = modulo(h, 360);
        const saturation = clamp(s, 0, 100) / 100;
        const value = clamp(v, 0, 100) / 100;
        let r: number;
        let g: number;
        let b: number;
        if (saturation === 0) {
            const allVals = Math.round(value * 255);
            return new Color({ r: allVals, g: allVals, b: allVals, a: alpha });
        }
        // This code was taken from dojox's fromHsv() implementation. I'm not sure what the logic is
        // here or what these variable names represent?
        // https://github.com/dojo/dojox/blob/41184d55a6b6d2b0645238e4ce427c7ac55cf823/color/_base.js#L92
        const hTemp = hue / 60;
        const i = Math.floor(hTemp);
        const f = hTemp - i;
        const p = value * (1 - saturation);
        const q = value * (1 - saturation * f);
        const t = value * (1 - saturation * (1 - f));
        switch (i) {
            case 0: {
                r = value;
                g = t;
                b = p;
                break;
            }
            case 1: {
                r = q;
                g = value;
                b = p;
                break;
            }
            case 2: {
                r = p;
                g = value;
                b = t;
                break;
            }
            case 3: {
                r = p;
                g = q;
                b = value;
                break;
            }
            case 4: {
                r = t;
                g = p;
                b = value;
                break;
            }
            case 5: {
                r = value;
                g = p;
                b = q;
                break;
            }
            default: {
                r = g = b = 0;
            }
        }
        return new Color({
            r: Math.round(r * 255),
            g: Math.round(g * 255),
            b: Math.round(b * 255),
            a: alpha,
        });
    }

    /**
     * Factory method to create a Color instance from hsl values (e.g. [240, 100, 100]) and an
     * optional alpha value.
     */
    static fromHsl({ h, s, l }: HslColorValues, alpha = 1): Color {
        const hue = modulo(h, 360);
        const saturation = clamp(s, 0, 100) / 100;
        const luminosity = clamp(l, 0, 100) / 100;
        let r: number;
        let g: number;
        let b: number;
        if (hue < 120) {
            r = (120 - hue) / 60;
            g = hue / 60;
            b = 0;
        } else if (hue < 240) {
            r = 0;
            g = (240 - hue) / 60;
            b = (hue - 120) / 60;
        } else {
            r = (hue - 240) / 60;
            g = 0;
            b = (360 - hue) / 60;
        }
        r = 2 * saturation * Math.min(r, 1) + (1 - saturation);
        g = 2 * saturation * Math.min(g, 1) + (1 - saturation);
        b = 2 * saturation * Math.min(b, 1) + (1 - saturation);
        if (luminosity < 0.5) {
            r *= luminosity;
            g *= luminosity;
            b *= luminosity;
        } else {
            r = (1 - luminosity) * r + 2 * luminosity - 1;
            g = (1 - luminosity) * g + 2 * luminosity - 1;
            b = (1 - luminosity) * b + 2 * luminosity - 1;
        }
        return new Color({
            r: Math.round(r * 255),
            g: Math.round(g * 255),
            b: Math.round(b * 255),
            a: alpha,
        });
    }

    /** Sanitize rgba values passed in by callers, and log any invalid values. */
    private sanitizeRgbaValue(val: number, isAlpha = false): number {
        const max = isAlpha ? 1 : 255;
        let retval = val;
        if (isNaN(val) || val < 0 || val > max) {
            Bugsnag.notify(Error(`Invalid value passed: ${val.toString()}`));
            retval = isNaN(val) ? max : clamp(val, 0, max);
        }
        return isAlpha ? retval : Math.round(retval);
    }

    /** Set the red value for the Color. Returns the Color. */
    setR(val: number): Color {
        this.r = this.sanitizeRgbaValue(val);
        return this;
    }

    /** Set the green value for the Color. Returns the Color. */
    setG(val: number): Color {
        this.g = this.sanitizeRgbaValue(val);
        return this;
    }

    /** Set the blue value for the Color. Returns the Color. */
    setB(val: number): Color {
        this.b = this.sanitizeRgbaValue(val);
        return this;
    }

    /** Set the alpha value for the Color. Returns the Color. */
    setA(val: number): Color {
        this.a = this.sanitizeRgbaValue(val, true);
        return this;
    }

    /** Get the rgba values for the Color. */
    toRgba(): RgbaColorValues {
        return { r: this.r, g: this.g, b: this.b, a: this.a };
    }

    /** Converts a Color to a hex string (e.g. "#ABCDEF"). */
    toHexString(): string {
        const toHexHelper = (c: number) => {
            const hexStr = c.toString(16);
            return hexStr.length < 2 ? "0" + hexStr : hexStr;
        };
        return `#${toHexHelper(this.r)}${toHexHelper(this.g)}${toHexHelper(this.b)}`;
    }

    /** Converts a Color to a css rgb(a) color string. */
    toCssString(): string {
        return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
    }

    /** Converts a Color to an hsv array. */
    toHsv(): HsvColorValues {
        const r = this.r / 255;
        const g = this.g / 255;
        const b = this.b / 255;
        const min = Math.min(r, b, g);
        const max = Math.max(r, g, b);
        const delta = max - min;
        let h: number;
        const s = max === 0 ? 0 : delta / max;
        if (s === 0) {
            h = 0;
        } else {
            if (r === max) {
                h = (60 * (g - b)) / delta;
            } else if (g === max) {
                h = 120 + (60 * (b - r)) / delta;
            } else {
                h = 240 + (60 * (r - g)) / delta;
            }
            if (h < 0) {
                h += 360;
            }
        }
        return { h, s: Math.round(s * 100), v: Math.round(max * 100) };
    }
}

/**
 * @deprecated This call is used by some of our older code and does not operate on Color class
 * instances. All new code should use our Color class and its corresponding methods.
 *
 * Returns the hex value of the given "color object". A "color object" should have either a
 * "color" hex string, or a "getColor" function that returns either a string or an object with
 * either a "toHex" function or a "toHexString" function.
 *
 * If the given object does not have any of these, defaultColor is returned instead. If defaultColor
 * is omitted, it defaults to our primary color.
 */
export function colorAsHex(color: any, defaultColor: EverColor = ColorTokens.PRIMARY): string {
    if (Is.string(color.color)) {
        return color.color;
    } else if (Is.func(color.getColor)) {
        const maybeColor = color.getColor();
        if (Is.func(maybeColor.toHexString)) {
            return maybeColor.toHexString();
        }
        // toHex is a method of legacy dojo Colors
        if (Is.func(maybeColor.toHex)) {
            return maybeColor.toHex();
        }
        return maybeColor;
    } else {
        return defaultColor;
    }
}

/**
 * Returns the Color that results from blending two colors. The fraction indicates the weight of
 * end; for example, if it is .25, then end's rgba values will be multiplied by .25, start's rgba
 * values will be multipled by .75, and the results will be added together and returned as a Color.
 */
export function blendColors(
    start: Color | EverColor,
    end: Color | EverColor,
    fraction: number,
): Color {
    const startRgba = (start instanceof Color ? start : Color.fromEverColor(start)).toRgba();
    const endRgba = (end instanceof Color ? end : Color.fromEverColor(end)).toRgba();
    const r = Math.round(startRgba.r + (endRgba.r - startRgba.r) * fraction);
    const g = Math.round(startRgba.g + (endRgba.g - startRgba.g) * fraction);
    const b = Math.round(startRgba.b + (endRgba.b - startRgba.b) * fraction);
    const a = startRgba.a + (endRgba.a - startRgba.a) * fraction;
    return new Color({ r, g, b, a });
}

/** Darkens the given Color by the specified amount. */
export function darkenColor(original: Color, fraction: number): Color {
    return blendColors(original, new Color(BLACK_RGB), fraction);
}

/**
 * Blend the given colors (specified by hex) by hue with constant saturation and value.
 */
export function blendColorsByHue(
    start: Color | EverColor,
    end: Color | EverColor,
    fraction: number,
): string {
    const startHsv = (start instanceof Color ? start : Color.fromEverColor(start)).toHsv();
    const endHsv = (end instanceof Color ? end : Color.fromEverColor(end)).toHsv();
    // Hue is an angle ([0, 360)) - to blend between two hues we want to take the shorter "path"
    // between them, which may loop around 0 or 360.  e.g. halfway from 0 to 180 is at 90, but
    // halfway from 0 to 270 is at -45 (i.e. 315).
    let hueDiff = endHsv.h - startHsv.h;
    if (Math.abs(hueDiff) > 180) {
        hueDiff += hueDiff > 0 ? -360 : 360;
    }
    return Color.fromHsv({
        h: (startHsv.h + fraction * hueDiff + 360) % 360,
        s: startHsv.s,
        v: startHsv.v,
    }).toCssString();
}

/**
 * Take in a Color or a hex string (e.g., "#FFFFFFFF"), set the alpha value (0 to 1), then return
 * the result as a css (rgba) string.
 */
export function setColorAlpha(color: Color | string, alpha: number): string {
    const c = color instanceof Color ? color : Color.fromHexString(color);
    return c.setA(alpha).toCssString();
}

/**
 * Returns a pseudo-random color based on the given id. The given value represents the V part of HSV
 * and is unaltered. The maxSaturation defaults to 90, and it represents the maximum S value; it
 * will be either maxSaturation or max(maxSaturation - 50, 20).
 */
export function colorForId(id: number, value: number, maxDarkness = 90): Color {
    // Cormen multiplicative hash
    // get the last 9 bits as the hash
    const hash = (2654289788 * id) >> (32 - 9);
    // the hue ranges from 0-359 and is set by the first 8 bits
    const hue = (hash & 0xff) * (360 / 256);
    // the saturation is either maxDarkness or maxDarkness - 50 depending on the 9th bit
    const sat = hash >> 8 ? maxDarkness : Math.max(maxDarkness - 50, 20);
    return Color.fromHsv({ h: Math.floor(hue), s: sat, v: value });
}

/**
 * Returns a psuedo-random color based on the given string. The given value represents the V part
 * of HSV and is unaltered. The maxSaturation defaults to 90, and it represents the maximum S value;
 * it will be either maxSaturation or max(maxSaturation - 50, 20).
 */
export function colorForString(inp: string, value: number, maxDarkness = 90): Color {
    let h = 0;
    for (let i = 0; i < inp.length; i++) {
        h = (31 * h + inp.charCodeAt(i)) | 0;
    }
    return colorForId(h, value, maxDarkness);
}

/**
 * Returns a color from a list of 30 HSV values preselected to have optimal contrast
 * for black text/white background.
 *
 * Caller must handle cases with > 30 values required.
 */
class PaletteColorPicker {
    private initialValues = [
        [200, 37, 84],
        [253, 29, 98],
        [30, 41, 100],
        [130, 72, 91],
        [360, 31, 90],
        [216, 14, 77],
        [37, 100, 100],
        [178, 58, 91],
        [61, 100, 84],
        [310, 33, 91],
        [69, 14, 77],
        [192, 33, 91],
        [20, 39, 87],
        [39, 72, 91],
        [49, 29, 98],
        [191, 72, 89],
        [89, 100, 100],
        [356, 41, 100],
        [77, 33, 91],
        [178, 100, 84],
        [311, 14, 81],
        [56, 100, 93],
        [15, 55, 100],
        [193, 25, 77],
        [198, 58, 91],
        [146, 33, 91],
        [248, 35, 100],
        [165, 100, 84],
        [33, 47, 85],
        [319, 46, 100],
    ];

    getNext(): Color | null {
        const values = this.initialValues.shift();
        // caller must handle cases with > 30 values
        return values ? Color.fromHsv({ h: values[0], s: values[1], v: values[2] }) : null;
    }
}

/**
 * Uses PaletteColorPicker for first 30 cases, then uses the following method to choose colors:
 *
 * Picks colors which vary by hue and attempt to space out the hues as much as possible, but without
 * knowing how many colors will be needed a priori. Does this by picking all colors in the hue color
 * space (0-359) which differ by a given increment, then when those are exhausted, goes through the
 * color space again picking the hues in between each of the previously chosen hues. This means that
 * the more colors are added, the smaller the difference between successive hues.
 *
 * With the default increment (and starting hue of 0), the colors produced would have hues:
 * 0, 180, 90, 270, 45, 135, 225, 315, 22, ...
 *
 * The value and saturation are held constant to optimize for accessibility and assume that these
 * colors will be used as a background for black text.
 */
export class AccessibilityColorPicker {
    private saturation = 25;
    private value = 90;
    private level = 0;
    private levelOffset = 0;
    private paletteColorPicker = new PaletteColorPicker();
    constructor(
        private increment = 180,
        private startingHue = 0,
    ) {}

    getNext(): Color {
        const paletteColor = this.paletteColorPicker.getNext();
        if (paletteColor) {
            return paletteColor;
        }
        let numerator;
        const denominator = 2 ** this.level;
        if (this.level === 0) {
            numerator = this.levelOffset;
        } else {
            numerator = this.levelOffset * 2 + 1;
        }
        const next =
            (Math.floor(this.increment * (numerator / denominator)) + this.startingHue) % 360;
        const offsetEnd = 2 ** Math.max(1, this.level) - 1;
        if (this.levelOffset === offsetEnd) {
            this.level++;
            this.levelOffset = 0;
        } else {
            this.levelOffset++;
        }

        return Color.fromHsv({ h: next, s: this.saturation, v: this.value });
    }
}
