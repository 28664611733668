/**
 * Sniff.ts: Utility functions related to browser/device detection
 *
 * WARNING: IN GENERAL, WE SHOULD AVOID USING THE FUNCTIONS IN THIS FILE AS MUCH AS POSSIBLE!
 * Serving different content to different browsers is usually a bad idea. It's very rarely a good
 * idea to use user agent sniffing like this. For ideas around using feature detection or other
 * alternatives, see:
 *
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
 */

/** Minimum supported version for each browser. Should probably be updated every year or so. */
const MINIMUM_SUPPORTED_BROWSER_VERSION = {
    firefox: 101,
    chrome: 100,
    safari: 16,
};

/** Returns the Firefox version, or undefined if the browser is not Firefox-based. */
export function getFirefoxVersion(): number | undefined {
    // userAgent must contain "Firefox/xyz" but not "Seamonkey/xyz"
    const version = parseFloat(navigator.userAgent.split("Firefox/")[1]);
    if (version && navigator.userAgent.indexOf("Seamonkey/") === -1) {
        return version;
    }
    return undefined;
}

/** Returns the Firefox version on iOS, or undefined if the browser is not Firefox on iOS */
export function getFirefoxOnIosVersion(): number | undefined {
    if (!userHasIOS()) {
        return undefined;
    }
    // For iOS devices, userAgent must contain "FxiOS/"
    const version = parseFloat(navigator.userAgent.split("FxiOS/")[1]);
    return version ? version : undefined;
}

/** Returns the Chrome version, or undefined if the browser is not Chrome-based (including Edge). */
export function getChromeVersion(): number | undefined {
    // userAgent must contain "Chrome/xyz" but not "Chromium/xyz"
    const version = parseFloat(navigator.userAgent.split("Chrome/")[1]);
    if (version && navigator.userAgent.indexOf("Chromium/") === -1) {
        return version;
    }
    return undefined;
}

/** Returns the Chrome/Edge version on iOS, or undefined if the browser is not chrome/edge on iOS */
export function getChromeOnIosVersion(): number | undefined {
    if (!userHasIOS()) {
        return undefined;
    }
    // For iOS devices, userAgent must contain "CriOS/" or "EdgiOS/"
    const version =
        parseFloat(navigator.userAgent.split("CriOS/")[1])
        || parseFloat(navigator.userAgent.split("EdgiOS/")[1]);
    return version ? version : undefined;
}

/**
 * Returns the Safari version, or undefined if the browser is not Safari-based.
 * Use {@link getFirefoxOnIosVersion} or {@link getChromeOnIosVersion} to check for Firefox/Chrome on iOS
 */
export function getSafariVersion(): number | undefined {
    // userAgent must contain "Safari/xyz" but not "Chrome/xyz", "Chromium/xyz", "CriOS/xyz", "FxiOS/xyz, "EdgiOS/xyz";
    if (
        navigator.userAgent.indexOf("Safari/") !== -1
        && navigator.userAgent.indexOf("Chrome/") === -1
        && navigator.userAgent.indexOf("Chromium/") === -1
        && navigator.userAgent.indexOf("FxiOS/") === -1
        && navigator.userAgent.indexOf("CriOS/") === -1
        && navigator.userAgent.indexOf("EdgiOS/") === -1
    ) {
        const version = parseFloat(navigator.userAgent.split("Version/")[1]);
        if (version) {
            return version;
        }
    }
    return undefined;
}

/** Returns true if the browser is supported, false otherwise. */
export function userHasSupportedBrowser(): boolean {
    const ffVer = getFirefoxVersion() || getFirefoxOnIosVersion();
    if (ffVer && ffVer >= MINIMUM_SUPPORTED_BROWSER_VERSION["firefox"]) {
        return true;
    }
    const chromeVer = getChromeVersion() || getChromeOnIosVersion();
    if (chromeVer && chromeVer >= MINIMUM_SUPPORTED_BROWSER_VERSION["chrome"]) {
        return true;
    }
    const safariVer = getSafariVersion();
    if (safariVer && safariVer >= MINIMUM_SUPPORTED_BROWSER_VERSION["safari"]) {
        return true;
    }
    return false;
}

/**
 * Returns true if the browser is Firefox-based, false otherwise.
 * @deprecated We should try to use feature detection instead of user agent sniffing, see
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
 */
export function userHasFirefox(): boolean {
    return !!getFirefoxVersion();
}

/**
 * Returns true if the browser is Chrome-based (including Edge), false otherwise.
 * @deprecated We should try to use feature detection instead of user agent sniffing, see
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
 */
export function userHasChrome(): boolean {
    return !!getChromeVersion();
}

/**
 * Returns true if the browser is Safari-based, false otherwise.
 * Note that Firefox and Chrome on iOS are just reskinned Safari
 * @deprecated We should try to use feature detection instead of user agent sniffing, see
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
 */
export function userHasSafari(): boolean {
    return !!getSafariVersion() || !!getFirefoxOnIosVersion() || !!getChromeOnIosVersion();
}

/** Returns true if the device is Android-based, false otherwise. */
export function userHasAndroid(): boolean {
    return navigator.userAgent.indexOf("Android") !== -1;
}

/**
 * Returns true if the device is iOS-based AND requesting the mobile version, false otherwise.
 *
 * NOTE: When Safari on iPad requests the desktop version of a site, the userAgent string will say
 * "Macintosh" instead of "iPad". We could try to look at the deprecated navigator.platform field
 * here (which should say "iPad"), but this has already been broken on the platform since 2019 when
 * iOS 13 started allowing for this (since dojo's sniff function was also looking at the userAgent).
 * To keep the same behavior, we'll treat the site like the desktop version when the user requests
 * the desktop version in iOS Safari.
 *
 * That also means you should not use the falsiness of this call as a proxy for testing touch
 * functionality, as it's possible for userHasIOS() to be false but userHasTouch() to be true.
 */
export function userHasIOS(): boolean {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
}

/** Returns true if the device is mobile, false otherwise. */
export function userHasMobile(): boolean {
    return userHasAndroid() || userHasIOS() || navigator.userAgent.indexOf("Mobi") !== -1;
}

/** Returns true if the device has touch support, false otherwise. */
export function userHasTouch(): boolean {
    return (!!window && "ontouchstart" in window) || navigator.maxTouchPoints > 0;
}

/** Returns true if the device is MacOS-based, false otherwise. */
export function userHasMacOS(): boolean {
    return !userHasIOS() && navigator.userAgent.indexOf("Mac") !== -1;
}
