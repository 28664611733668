import Server = require("Everlaw/Server");
import * as React from "react";
/**
 * Declares the subset of Bugsnag functionality that our TypeScript code uses. Add more as needed.
 */

/**
 * Send caught exceptions to Bugsnag. Other `options` properties that could be added are listed
 * at https://docs.bugsnag.com/platforms/javascript/reporting-handled-errors/ .
 */
export function notify(exception: Error, options: BugsnagOptions = {}): void {
    // Bugsnag now takes a callback as the second parameter, so we take the options
    // passed in and use it to create the callback
    BugsnagClient.notify(exception, (event: BugsnagNotifyEvent) => {
        if (options.ignore) {
            return false;
        }
        if (options.severity) {
            event.severity = options.severity;
        }
        if (!Server.isFedRamp() && options.error) {
            event.errors[0].errorClass = options.error.errorClass;
            event.errors[0].errorMessage = options.error.errorMessage;
        }
        // Add metadata to the event object:
        // https://docs.bugsnag.com/platforms/javascript/restify/customizing-error-reports/#addmetadata
        if (!Server.isFedRamp() && options.metaData) {
            event.addMetadata("Custom", options.metaData);
        }
    });
    if (typeof displayErrorBanner !== "function") {
        // The error banner is only included on non-live instances, and on pages that include
        // header.jsp (i.e. most pages, aside from a few exceptions like the login page).
        return;
    }
    displayErrorBanner(exception.message, exception.stack);
}

/**
 * Similar to {@link notify} but sets a custom Error name before sending to Bugsnag. Make sure not
 * to include any sensitive information disallowed by FedRAMP to `name`, since we don't remove it
 * before sending to Bugsnag on FedRAMP instances (see `headHeader.jsp` for what we remove).
 */
export function notifyCustomName(
    name: string,
    exception: Error,
    options: BugsnagOptions = {},
): void {
    if (exception.name && exception.name !== "Error") {
        // Save a potentially helpful error name before replacing with the custom one.
        exception.message = exception.name + ": " + exception.message;
    }
    exception.name = name;
    notify(exception, options);
}

/**
 * See https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/ for more options for
 * customizing error reports, which can be added to this interface as needed.
 */
interface BugsnagOptions {
    // Valid values are `info`, `warning`, and `error`, in order of increasing severity.
    severity?: "info" | "warning" | "error";
    // These properties will be displayed under the "Custom" tab on Bugsnag. Unless a property is
    // an object itself, in which case the top level key will be the name of a tab and the key-value
    // properties will be displayed under that tab.
    metaData?: any;
    error?: BugsnagError;
    ignore?: boolean;
}

interface BugsnagError {
    errorClass: string;
    errorMessage: string;
}

interface BugsnagPluginReactResult {
    createErrorBoundary(react?: typeof React): React.FC;
}

interface BugsnagNotifyEvent {
    severity: string;
    errors: {
        errorClass: string;
        errorMessage: string;
    }[];
    addMetadata: (arg0: string, arg1: any) => void;
}

export function getReactPlugin(): BugsnagPluginReactResult {
    return BugsnagClient.getPlugin("react") as BugsnagPluginReactResult;
}
