import type { Recommendation } from "Everlaw/SmartOnboarding/Recommendation";

let activeRecommendation: Recommendation | null = null;

/**
 * This should only be called after {@link Recommendation recommendations} are fully loaded
 * from the backend. See `RecommendationInit#init` for where we decide recommendations have been
 * loaded. This function resolves the {@link Promise} {@link #recommendationsLoaded}, and allows
 * all other functions waiting on it to continue executing. See {@link Recommendation#isShown} for
 * an example usage. This should only be set once by {@link #recommendationsLoaded}.
 *
 * This prevents recommendations from attempting to activate themselves off of inaccurate
 * information. Some recommendations rely on data being loaded before we can correctly decide
 * whether to activate them. See the usages of {@link Recommendations#CUSTOMIZE_REVIEW_LAYOUT}
 * for example.
 */
export let setRecommendationsLoaded: (value: boolean | PromiseLike<boolean>) => void;

/**
 * This must be used when you rely on the state of a {@link Recommendation} being fully loaded
 * from the backend. This is applicable for reading values like
 * {@link RecommendationStatus.triggered}.
 *
 * See {@link #setRecommendationsLoaded} for an explanation of how this {@link Promise} will be
 * resolved.
 */
export const recommendationsLoaded = new Promise<boolean>(
    (resolve) => (setRecommendationsLoaded = resolve),
);

export function setActiveRecommendation(activeRec: Recommendation | null): void {
    activeRecommendation = activeRec;
}

/**
 * The {@link Recommendation} the user is currently viewing, or null if they aren't in the process
 * of viewing a recommendation.
 */
export function getActiveRecommendation(): Recommendation | null {
    return activeRecommendation;
}
