/*
This file should import nothing because it may be used outside the platform.
 */

/**
 * {@link Element} attribute that can be used to identify Elements by text content for use with
 * Heap Analytics. This attribute is automatically generated for some components like Buttons
 * and PopoverMenu items based on their text content. The goal is to allow tracking on elements
 * that may otherwise be difficult to differentiate, without requiring a dev to manually add
 * `EverId`s or `EverClass`es. This attribute is hashed to prevent exposing case-sensitive material.
 *
 * Note: Any significant changes made to the EverHash system must be communicated with Product
 * and anyone who manages Heap Analytics.
 */
const EVERHASH_ATTRIBUTE = "data-everhash";

/**
 * Taken from https://github.com/garycourt/murmurhash-js/blob/master/murmurhash3_gc.js
 * and tweaked to remove linting errors. Additional changes are as follows:
 * - The `seed` parameter has been removed because we want the hash of a string to be constant
 * - If any characters are non-ASCII, returns 0 and exits early
 *
 * JS Implementation of MurmurHash3 (r136) (as of May 20, 2011)
 *
 * @author <a href="mailto:gary.court@gmail.com">Gary Court</a>
 * @see http://github.com/garycourt/murmurhash-js
 * @author <a href="mailto:aappleby@gmail.com">Austin Appleby</a>
 * @see http://sites.google.com/site/murmurhash/
 *
 * @param {string} key ASCII only
 * @return {number} 32-bit positive integer hash
 */
function murmurHash(key: string): number {
    // Enforce ASCII-only requirement
    if (!key || [...key].some((char) => char.charCodeAt(0) > 127)) {
        return 0;
    }

    let h1b;
    let k1;
    let i;

    const remainder = key.length & 3; // key.length % 4
    const bytes = key.length - remainder;
    let h1 = 1; // Hard-coded seed
    const c1 = 0xcc9e2d51;
    const c2 = 0x1b873593;
    i = 0;

    while (i < bytes) {
        k1 =
            (key.charCodeAt(i) & 0xff)
            | ((key.charCodeAt(++i) & 0xff) << 8)
            | ((key.charCodeAt(++i) & 0xff) << 16)
            | ((key.charCodeAt(++i) & 0xff) << 24);
        ++i;

        k1 = ((k1 & 0xffff) * c1 + ((((k1 >>> 16) * c1) & 0xffff) << 16)) & 0xffffffff;
        k1 = (k1 << 15) | (k1 >>> 17);
        k1 = ((k1 & 0xffff) * c2 + ((((k1 >>> 16) * c2) & 0xffff) << 16)) & 0xffffffff;

        h1 ^= k1;
        h1 = (h1 << 13) | (h1 >>> 19);
        h1b = ((h1 & 0xffff) * 5 + ((((h1 >>> 16) * 5) & 0xffff) << 16)) & 0xffffffff;
        h1 = (h1b & 0xffff) + 0x6b64 + ((((h1b >>> 16) + 0xe654) & 0xffff) << 16);
    }

    k1 = 0;

    switch (remainder) {
        case 3:
            k1 ^= (key.charCodeAt(i + 2) & 0xff) << 16;
            break;
        case 2:
            k1 ^= (key.charCodeAt(i + 1) & 0xff) << 8;
            break;
        case 1:
            k1 ^= key.charCodeAt(i) & 0xff;

            k1 = ((k1 & 0xffff) * c1 + ((((k1 >>> 16) * c1) & 0xffff) << 16)) & 0xffffffff;
            k1 = (k1 << 15) | (k1 >>> 17);
            k1 = ((k1 & 0xffff) * c2 + ((((k1 >>> 16) * c2) & 0xffff) << 16)) & 0xffffffff;
            h1 ^= k1;
    }

    h1 ^= key.length;

    h1 ^= h1 >>> 16;
    h1 = ((h1 & 0xffff) * 0x85ebca6b + ((((h1 >>> 16) * 0x85ebca6b) & 0xffff) << 16)) & 0xffffffff;
    h1 ^= h1 >>> 13;
    h1 = ((h1 & 0xffff) * 0xc2b2ae35 + ((((h1 >>> 16) * 0xc2b2ae35) & 0xffff) << 16)) & 0xffffffff;
    h1 ^= h1 >>> 16;

    return h1 >>> 0;
}

/**
 * Given the {@link Element} and its text content, adds EverHash to the element.
 * @param element Element to add the EverHash to.
 * @param textToHash The text content of {@link element} to hash and add as the EverHash.
 */
export function setEverHash<E extends Element>(element: E, textToHash: string): E {
    textToHash && element.setAttribute(EVERHASH_ATTRIBUTE, String(murmurHash(textToHash)));
    return element;
}

/**
 * Constructs a selector string for the EverHash of the given {@link textToHash}. This can
 * be passed to anything expecting a CSS selector.
 * @param textToHash The text content to construct an EverHash selector string for.
 */
export function everHashSelector(textToHash: string): string {
    return `[${EVERHASH_ATTRIBUTE}="${murmurHash(textToHash)}"]`;
}

/**
 * Generates an object containing an EverHash that can be spread (`...`) as props into a TSX/React
 * element or attribute object. If no {@link textToHash} is provided, returns an empty object.
 * @param textToHash The text to hash and assign as a prop.
 */
export function everHashProp(textToHash?: string): Record<string, string> {
    return !textToHash ? {} : { [EVERHASH_ATTRIBUTE]: String(murmurHash(textToHash)) };
}

// Types copied from Dom.ts, since this file can't import anything.
export type Content = string | number | Node | ContentArray | null | undefined;
interface ContentArray extends Array<Content> {}

/**
 * Given the content of an element, returns the text to hash for `EVERHASH_ATTRIBUTE`.
 * The resulting text to hash consists of the string and number elements pulled recursively from
 * the given content, separated by a space. Nodes are ignored.
 */
export function getTextToHash(content: Content): string | null {
    let textToHash = null;
    if (typeof content === "string") {
        textToHash = content;
    } else if (typeof content === "number") {
        textToHash = String(content);
    } else if (Array.isArray(content)) {
        textToHash = content
            .map((part) => getTextToHash(part))
            .filter((text) => !!text)
            .join(" ");
    }
    return textToHash;
}
