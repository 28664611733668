/**
 * Functions that do not currently exist on ECMAScript maps
 */

/**
 * Gets the value for the key if it exists and is not undefined,
 * or puts a default into the map at that key and returns it.
 * This will treat keys that map to `undefined` as absent.
 * If your map really requires keys that map to nothing, but are actually in the map,
 * consider using null as a value.
 * @param map Map to retrieve value from and possibly place defaultValue into
 * @param key Key to get value for and possibly insert into map
 * @param defaultVal Value inserted into map under key if key is not in map or maps to undefined
 */
export function computeIfAbsent<K, V>(map: Map<K, V>, key: K, defaultVal: V): V {
    const value = map.get(key);
    if (value === undefined) {
        map.set(key, defaultVal);
        return defaultVal;
    }
    return value;
}

/**
 * Remove all keys from collection that occur in keysToRemove.
 * Function is Variadic.
 * Modeled on Java Set#removeAll.
 *
 * @param collection Map or Set to remove from
 * @param keysToRemove Keys to remove in a variable number of iterables
 * @return true if collection was modified
 */
export function removeAll<K, V>(
    collection: Map<K, V> | Set<K>,
    ...keysToRemove: Array<IterableIterator<K>>
): boolean {
    let changed = false;
    for (const toRemove of keysToRemove) {
        for (const key of toRemove) {
            if (collection.delete(key)) {
                changed = true;
            }
        }
    }
    return changed;
}

/**
 * Removes all entries from a Map that do not satisfy the given filter.
 * @param map - The Map from which entries will be removed.
 * @param predicate - A function that tests each entry. If it returns true, the entry is removed.
 * @returns The number of entries removed.
 */
export function filterMap<K, V>(map: Map<K, V>, filter: (key: K, value: V) => boolean): number {
    let removedCount = 0;

    map.forEach((value, key) => {
        if (!filter(key, value)) {
            map.delete(key);
            removedCount++;
        }
    });

    return removedCount;
}

/**
 * This helper function removes keys which are not in set from map, and adds keys with default values
 * for values in set, but not in map.
 * Example:
 *
 *      input: set = (1, 2, 3) and map = (1: true, 4: false)
 *
 *      output: map = (1: true, 2: false, 3: false)
 *
 * @return a new copy of the updated map
 */
export function setMapKeysFromSet<K, V>(map: Map<K, V>, set: Set<K>, defaultValue: V): Map<K, V> {
    // add new entries to the map
    for (const id of set) {
        if (!map.has(id)) {
            map.set(id, defaultValue);
        }
    }
    // remove outdated entries from the map
    for (const k of map.keys()) {
        if (!set.has(k)) {
            map.delete(k);
        }
    }
    return new Map(map);
}

/**
 * Returns a map containing the number of occurrences of each object in `values`.
 */
export function getCounts<T>(values: Iterable<T>): Map<T, number> {
    const result: Map<T, number> = new Map();
    for (const value of values) {
        result.set(value, (result.get(value) ?? 0) + 1);
    }
    return result;
}
