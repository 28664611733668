import Dom = require("Everlaw/Dom");
import Input = require("Everlaw/Input");
import Tooltip = require("Everlaw/UI/Tooltip");
import UI = require("Everlaw/UI");
import Util = require("Everlaw/Util");
import dojo_on = require("dojo/on");
import eventUtil = require("dojo/_base/event");
import * as Is from "Everlaw/Core/Is";
import { FocusDiv, makeFocusable } from "Everlaw/UI/FocusDiv";

/**
 * Base class for a node with an onClick listener and/or a tooltip.
 */
class ActionNode {
    node: HTMLElement;
    tooltip: Tooltip;
    onClick: (evt: Event) => void;
    focusDiv: FocusDiv | null = null;
    protected toDestroy: Util.Destroyable[] = [];
    constructor(nodeable: Dom.Nodeable, params: ActionNode.Params) {
        this.node = Dom.node(nodeable);
        if (params.tooltip) {
            this.tooltip = new Tooltip(this.node, params.tooltip, params.tooltipPosition);
            this.toDestroy.push(this.tooltip);
        }
        if (params.makeFocusable) {
            this.focusDiv = makeFocusable(
                this.node,
                params.focusStyling || "focus-with-space-style",
                params.focusDivPos,
                params.focusDivUseInline,
            );
            this.toDestroy.push(this.focusDiv);
        }
        // allows callers to update the onClick function
        if (params.onClick) {
            this.onClick = (evt) => {
                params.onClick?.(evt);
            };
            this.toDestroy.push(
                dojo_on(this.node, params.usePress ? Input.press : Input.tap, (e: Event) => {
                    this.whenClicked(e);
                }),
            );
            Dom.addClass(this.node, "action");
            if (params.makeFocusable && this.focusDiv) {
                this.toDestroy.push(
                    Input.fireCallbackOnKey(
                        this.focusDiv.node,
                        [Input.ENTER, Input.SPACE],
                        (e: Event) => this.whenClicked(e),
                    ),
                );
            }
        }
    }
    getNode(): HTMLElement {
        return this.node;
    }
    private whenClicked(evt: Event): void {
        eventUtil.stop(evt);
        this.tooltip && this.tooltip.close();
        UI.hideTooltips();
        !Dom.hasAttr(this.node, "disabled") && this.onClick(evt);
    }
    setDisabled(disabled?: boolean) {
        UI.toggleDisabled(this.node, disabled);
        Dom.toggleClass(this.node, "disabled", disabled);
    }
    destroy() {
        Util.destroy(this.toDestroy);
        this.focusDiv = null;
        this.toDestroy = [];
    }
    registerDestroyable(...items: Util.Destroyable[]): void {
        this.toDestroy.push(items);
    }
}

module ActionNode {
    export interface Params {
        // Click handler on the node. If specified, the `action` class is added to the node as well.
        onClick?: (evt: Event) => void;
        // If true, the onClick callback is connected to Input.press instead of Input.tap.
        usePress?: boolean;
        // If specified, a tooltip is created and attached to the node.
        tooltip?: Dom.Content;
        // List of positions for the tooltip.
        tooltipPosition?: string[];
        // If true, the ActionNode will be made focusable
        makeFocusable?: boolean;
        // If makeFocusable is true, it will use this styling. focus-with-space-style is the default.
        focusStyling?: string | string[];
        // If makeFocusable is true, it will use this position.
        // The options are the same as in Dom.place().
        focusDivPos?: string;
        // If focusDivUseInline is true and makeFocusable is also true, the focusDiv will use
        // inline display.
        focusDivUseInline?: boolean;
    }

    export function textAction(
        text: string,
        onClick?: (evt: Event) => void,
        tooltip?: Dom.Content,
        href?: string,
        shouldBeFocusable?: boolean,
        clazz?: string,
        focusDivUseInline = false,
        openInNewTab = false,
    ) {
        const textContent = href
            ? Dom.a({ href, target: openInNewTab ? "_blank" : "_self" }, text)
            : text;
        clazz = "text-action" + (Is.defined(clazz) ? " " + clazz : "");
        const createdTextAction = new ActionNode(Dom.span({ class: clazz }, textContent), {
            onClick,
            tooltip,
            makeFocusable: !href && shouldBeFocusable,
            focusStyling: "focus-text-style",
            focusDivUseInline,
        });
        // If the text is meant to be a keyboard-focusable link, the ActionNode will not be created
        // correctly. Here, we attach the FocusDiv to the link node instead of the span surrounding it.
        if (textContent instanceof HTMLAnchorElement && shouldBeFocusable) {
            const focusDiv = makeFocusable(
                textContent,
                "focus-text-style",
                undefined,
                focusDivUseInline,
            );
            createdTextAction.registerDestroyable(
                focusDiv,
                Input.fireCallbackOnKey(focusDiv.node, [Input.ENTER], () => textContent.click()),
            );
        }
        return createdTextAction;
    }
}

export = ActionNode;
